<template>
  <div>
    <div class="container-top bg">
      <!-- pc -->
      <div class="pc signup-container">
        <div class="signup-wrapper">
          <div class="subtitle3 main" style="margin-bottom: 16px">파트너 가입완료</div>
          <img src="/static/img/img_success.svg" style="margin-bottom:40px" />
          <div class="body2-bold primary">Welcome!</div>
          <div class="body2-medium sub2" style="margin-top:8px">파트너 가입이 완료되었습니다.<br />파트너 정보와 템플릿을 등록해 제작문의를 받아보세요.</div>
          <div style="margin-top:40px;width:100%">
            <router-link to="/partner_center/partner_info">
              <button class="button is-primary body2-medium" style="width:100%">
                파트너 정보 등록하기
              </button>
            </router-link>
            <router-link to="/partner_center">
              <button class="button is-primary-light body2-medium" style="width:100%;margin-top:12px">파트너 센터 바로가기</button>
            </router-link>
          </div>
        </div>
      </div>
      <!-- mobile -->
      <div class="mobile signup-container">
        <div class="signup-wrapper">
          <div class="h7 main" style="margin-bottom: 16px">파트너 가입완료</div>
          <img src="/static/img/img_success.svg" style="margin-bottom:32px;width:140px;height:140px" />
          <div class="body2-bold primary">Welcome!</div>
          <div class="body4 sub2" style="margin-top:8px">파트너 가입이 완료되었습니다.<br>파트너 정보와 템플릿을 등록해 제작문의를 받아보세요.</div>

          <div style="margin:40px 0;width:100%">
            <router-link to="/partner_center/partner_info">
              <button class="button is-primary body2-medium" style="height:48px;width:100%;">
                파트너 정보 등록하기
              </button>
            </router-link>
            <router-link to="/partner_center">
              <button class="button is-primary-light body2-medium" style="height:48px;width:100%;margin-top:12px">
                파트너 센터 바로가기
              </button>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PartnerSignupDone',
  data() {
    return {
    }
  },
  methods: {
  },
};
</script>

<style lang="stylus" scoped>
@import '~assets/css/lp_main'
.bg
  background-color $gray4
  display flex
  justify-content center
  align-items flex-start
  height calc(-64px + 100vh)
.signup-container
  margin-top 40px
  width 448px
  text-align center

.signup-wrapper
  border 1px solid $gray2
  border-radius 12px
  background-color white
  padding 40px
  margin-top 24px

.signup-label
  text-align left
  margin-top 16px

.circle
  width 24px
  height 24px
  border-radius 100%
  line-height 24px
  text-align center
  background-color $sub5
  color white
  margin-right 4px

.circle-select
  background-color $main

.step
  margin-left 45px

.step:first-child
  margin-left 0

@media (max-width:1024px)
  .signup-container
    margin 0 16px
  .signup-wrapper
    padding 32px 20px
  .signup-label
    font-size 14px
  .step
    margin-left 0
    width 33.33333%
    justify-content center
</style>
